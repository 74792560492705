.fade-enter-active,
.fade-exit-active,
.slide-right-enter-active,
.slide-right-exit-active,
.slide-left-enter-active,
.slide-left-exit-active {
  transition: opacity 200ms ease-out, transform 300ms ease-out;
}

.transition,
.fade,
.slide-right,
.slide-left {
  position: absolute;
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  padding: inherit;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(30%);
}

.slide-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-right-exit-active {
  opacity: 0;
  transform: translateX(-30%);
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(-30%);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0%);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(30%);
}
