/** Open-Sans */

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans/open-sans-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans/open-sans-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-600.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans/open-sans-v17-latin-700.ttf') format('truetype'); /* Safari, Android, iOS */
}

/** Roboto */

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
}