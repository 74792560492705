$xsmall: '480px';
$small: '768px';
$medium: '1024px';
$large: '1280px';
$xlarge: '1920px';

.hide-xs {
  @media (max-width: $xsmall) {
    display: none;
  }
}

.hide-sm {
  @media (max-width: $small) {
    display: none;
  }
}

.hide-md {
  @media (max-width: $medium) {
    display: none;
  }
}

.hide-lg {
  @media (max-width: $large) {
    display: none;
  }
}

.hide-xl {
  @media (max-width: $xlarge) {
    display: none;
  }
}
