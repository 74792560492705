$sizes: 124;

@mixin gap-classes {
  @for $i from 0 through $sizes {
    $gap: $i * 1px;

    .gap-#{$i} {
      gap: $gap !important;
    }
  }
}

@include gap-classes;
